import React, {useRef, useState} from 'react';
import {connect} from 'react-redux';
import {
    ORDER_PAGE_LOADED,
    ORDER_PAGE_UNLOADED,
    ORDER_STATUS_LOADED, ORGANIZATION_LOAD, POSTAL_SERVICE_FILTER_LOADED, CLIENTS_LOAD,
    POSTAL_SERVICE_PAGE_LOADED,
    CALC_TARIFF_USLUGA_PS_LOAD,
    REPORT_LOAD,
    SEARCH_STATE_UPDATE,
    SLQ_USER_OPTIONS_LOAD,
    SLQ_USER_OPTIONS_UNLOAD,
    CALC_TARIFF_POSTAL_SERVICES_LOAD
} from "../../constants/actionTypes";
import {Dropdown} from "primereact/dropdown";
import agent from "../../agent";
import {Fieldset} from "primereact/fieldset";
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import {Button} from "primereact/button";
import {Calendar} from "primereact/calendar";
import {classNames} from "primereact/components/utils/ClassNames";
import {Menu} from "primereact/menu";
import './DataTableDemo.css'
import {history} from '../../store';
import ru from '../../locale/calendar'
import {Paginator} from "primereact/paginator";
import {addLocale} from "primereact/api";
import {Link} from "react-router-dom";
import {InputText} from "primereact/inputtext";
import {MultiSelect} from "primereact/multiselect";
import {trim} from "../../utils/util";
import SearchSelect from "../Calculator/SearchSelect";
import ModalReports from './components/ModalReports';

const mapStateToProps = state => ({
    ...state.postalService,
    ...state.orderStatus,
    ...state.order,
    ...state.searchState,
    ...state.report,
    ...state.orgs,
    ...state.slqUser,
    ...state.calcTariff
});

const mapDispatchToProps = dispatch => ({
    onLoad: (payload, payload2, payload3, payload4, payload5, payload6, postalServicesPayload) => {
        dispatch({ type: POSTAL_SERVICE_FILTER_LOADED, payload });
        dispatch({ type: ORDER_STATUS_LOADED, payload: payload2 });
        dispatch({ type: ORDER_PAGE_LOADED, payload: payload3 });
        dispatch({ type: REPORT_LOAD, payload: payload4 });
        dispatch({ type: ORGANIZATION_LOAD, payload: payload5 });
        dispatch({ type: CLIENTS_LOAD, payload: payload6 });
        dispatch({ type: CALC_TARIFF_POSTAL_SERVICES_LOAD, payload: postalServicesPayload });
    },
    onSlqUserSearch: (payload) =>
        dispatch({ type: SLQ_USER_OPTIONS_LOAD, payload }),
    onUnload: () => {
        dispatch({type: ORDER_PAGE_UNLOADED})
        dispatch({type: SLQ_USER_OPTIONS_UNLOAD})
    },
    onSearch: (payload) => {
        dispatch({ type: ORDER_PAGE_LOADED, payload })
    },
    onMount: (payload) => {
        dispatch({ type: SEARCH_STATE_UPDATE, payload })
    },
    onPostalServicePicked: (uslugaPsPayload) => {
        dispatch({ type: CALC_TARIFF_USLUGA_PS_LOAD, payload: uslugaPsPayload})
    },
});


const RowMenu = (props) => {
    const menu = useRef(null);
    const items = [
        {
            label: 'Навигация',
            items: [
                {
                    label: 'Просмотеть',
                    icon: 'pi pi-external-link',
                    command: () => {
                        history.push(`/order/${props.order.id}`)
                    }
                }
            ]
        }
    ];

    return (
        <div className="card">
            <Menu model={items} popup ref={menu} id="popup_menu" className="p-menuitem"/>
            <Button
                icon="pi pi-cog" className="p-button-secondary"
                onClick={(event) => { menu.current.toggle(event) }}
                aria-controls="popup_menu"
                aria-haspopup
            />
        </div>
    );
}

const OrderTable = (props) => {
    const [order, setSelectedOrder] = useState(null);

    const dt = useRef(null);

    const trackBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <div className='table-cell'> 
                    <span className="p-column-title">Трек номер SLQ</span>
                    <Link to={`/order/${rowData.track}`} className="order__link">{rowData.track}</Link>
                    <Button onClick={() =>  navigator.clipboard.writeText(rowData.track)} className="p-button-secondary p-button-text">
                        <i className="pi pi-copy" style={{'paddingLeft': '2px'}}/>
                    </Button>
                </div>
            </React.Fragment>
        );
    }

    const clientOrderNumBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <div className='table-cell'>
                    <span className="p-column-title">Клиентский номер доставки</span>
                    <span>{rowData.clientOrderNum}</span>
                    {!!rowData.clientOrderNum ? <Button onClick={() =>  navigator.clipboard.writeText(rowData.clientOrderNum)} className="p-button-secondary p-button-text">
                        <i className="pi pi-copy" style={{'paddingLeft': '2px'}}/>
                    </Button> : null}
                </div>
            </React.Fragment>
        );
    }

    const psTrackBodyTemplate = (rowData) => {
        const data = ((rowData || {}).psTrack||[]).join(', ');
        return (
            <React.Fragment>
                <div className="p-fluid p-formgrid p-grid">
                    <div className='table-cell'>
                        <div className="p-field p-col-6 p-md-6">
                            <span className="p-column-title">Трек номер грузоперевозчика</span>
                            <p>{data}</p>
                        </div>
                        {!!data ? <div className="p-field p-col-6 p-md-3">
                            <Button onClick={() =>  navigator.clipboard.writeText((rowData || []).psTrack)} className="p-button-secondary p-button-text">
                                <i className="pi pi-copy" style={{'paddingLeft': '2px'}}/>
                            </Button>
                        </div> : null}
                    </div>
                </div>
            </React.Fragment>
        );
    }


    const psNameBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <div className='table-cell'>
                    <span className="p-column-title">Грузоперевозчик</span>
                    <span style={{verticalAlign: 'middle', marginLeft: '.5em'}}>{rowData.psName}</span>                    
                </div>
            </React.Fragment>
        );
    }

    const psServiceBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <div className='table-cell'>
                    <span className="p-column-title">Услуга грузоперевозчика</span>
                    <span style={{verticalAlign: 'middle', marginLeft: '.5em'}}>{rowData.psService}</span>
                </div>
            </React.Fragment>
        );
    }

    const orgNameBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <div className='table-cell'>
                    <span className="p-column-title">Клиент</span>
                    <span style={{verticalAlign: 'middle', marginLeft: '.5em'}}>{rowData.orgName}</span>
                </div>
            </React.Fragment>
        );
    }


    const statusBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <div className='table-cell'>
                    <span className="p-column-title">Статус доставки</span>
                    <span className={classNames('customer-badge')}>{rowData.status}</span>
                </div>
            </React.Fragment>
        );
    }

    const psDatePickupBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <div className='table-cell'>
                    <span className="p-column-title">Фактическая дата доставки</span>
                    <span className={classNames('customer-badge')}>{rowData.psDeliveryDate}</span>
                </div>
            </React.Fragment>
        );
    }

    return (

        <DataTable ref={dt} value={(props.orders||{content: []}).content}
                   className="p-datatable-customers" dataKey="id" rowHover selection={order}
                   emptyMessage="Данные не найдены" scrollable>
            {/*<Column body={actionBodyTemplate} headerStyle={{width: '8em', textAlign: 'center'}} bodyStyle={{textAlign: 'center', overflow: 'visible'}} />*/}
            <Column headerStyle={{ width: '200px' }} columnKey="track" field="track" header="Трек номер SLQ" body={trackBodyTemplate} sortable/>
            <Column headerStyle={{ width: '225px' }} columnKey="psTrack" field="psTrack" header="Трек номер грузоперевозчика" body={psTrackBodyTemplate} sortable/>
            <Column headerStyle={{ width: '150px' }} columnKey="clientOrderNum" field="clientOrderNum" header="Клиентский номер доставки" body={clientOrderNumBodyTemplate} sortable/>
            <Column headerStyle={{ width: '125px' }} columnKey="psName" field="psName" header="Грузоперевозчик" body={psNameBodyTemplate} sortable />
            <Column headerStyle={{ width: '125px' }} columnName="psService" field="psService" header="Услуга грузоперевозчика" body={psServiceBodyTemplate} sortable />
            <Column headerStyle={{ width: '125px' }} columnKey="orgName" field="orgName" header="Клиент" body={orgNameBodyTemplate} sortable />
            <Column headerStyle={{ width: '150px' }} columnKey="status" field="status" header="Статус доставки" body={statusBodyTemplate} sortable />
            {/*<Column headerStyle={{ width: '250px' }} columnKey="psStatus" field="psStatus" header="Статус доставки Грузоперевозчика" body={psStatusBodyTemplate} sortable />*/}
            <Column headerStyle={{ width: '100px' }} columnKey="psDeliveryDate" field="psDeliveryDate" header="Фактическая дата доставки" body={psDatePickupBodyTemplate} sortable />
        </DataTable>
    );
}

const formatDate  = (val) => {
    if (val) {
        return val.toString().padStart(2, '0');
    }
    return val;
}

const searchQuery = (state) => {
    const search = {
        ps: state.psId,
        status: state.orderStatusId,
        take: state.datePickup,
        atake: state.actualDatePickup,
        delivery: state.deliveryDate,
        psdelivery: state.psDeliveryDate,
        adelivery: state.actualDeliveryDate,
        orderNum: state.orderNum ? state.orderNum.toUpperCase() : state.orderNum,
        clientOrderNum: state.clientOrderNum,
        clientNum: state.clientNum,
        page: state.page,
        pageSize: state.pageSize,
        orgId: state.orgId,
        clientName: state.clientCode,
        psOrderNum: state.psOrderNum,
        userGuid: state.slqUser.id,
        psService: state.psService,
        isAlert: state.isAlert
    }
    let q = '';
    for (let [key, value] of Object.entries(search)) {
        value = trim(value);
        if (value || value === false) {
            if (Array.isArray(value) && (!key.includes('status') && !key.includes('psService'))) {
                let from = new Date(value[0]);
                let to = value[1] ? new Date(value[1]) : new Date();
                value = `${formatDate(from.getDate())}.${formatDate(from.getMonth() + 1)}.${from.getFullYear()}-${formatDate(to.getDate())}.${formatDate(to.getMonth() + 1)}.${to.getFullYear()}`;
            }
            q = !q.includes(`${key}=${value}`) ? (q || '&') + `${key}=${value}&` : '';
        }

    }
    if (q && q.length > 1) {
        q = q.substring(0, q.length - 1);
    }
    return q || '';
}

const clientAttributs = [
    { value: 'omarket-paid', name: 'Омаркет с подпиской' },
    { value: 'omarket-unpaid', name: 'Омеркет без подписки' },
    { value: 'goszakup', name: 'ГЗ' },    
];

const modals = ['reports'];

class Orders extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            isLoaded: false,
            psId: null,
            orderStatusId: null,
            panelCollapsed: false,
            datePickup: null,
            actualDatePickup: null,
            deliveryDate: null,
            psDeliveryDate: null,
            actualDeliveryDate: null,
            orderNum: '',
            clientOrderNum: '',
            clientNum: '',
            page: 0,
            pageSize: 10,
            orgId: null,
            clientCode: '',
            psOrderNum: '',
            slqUser: {id: ''},
            psService: null,
            uslugaPs: [],
            openedModals: '',
            isAlert: null
        }

        this.setOpenedModals = (name) => {
            this.setState({ openedModals: name });
        }

        this.onChangeFrom = (event) => {
            this.props.onSlqUserSearch(agent.SlqUser.allOptions(event));
        }

        this.setSlqUser = (event) => {
           this.setState({ slqUser: {id: event.id} })
        }

        this.onPageChange = (event) => {
            this.setState({page: event.page, pageSize: event.rows, first: event.first}, () => {
                const q = searchQuery(this.state);
                this.props.onSearch(agent.Orders.all(event.page, event.rows, q));
                history.push(`/orders?${q.length > 1 ? q.substring(1) : q}`);
            });
        }

        this.clearForm = () => ev => {
            ev.preventDefault();

            this.props.onMount({
                params: null,
                urlValue: false,
            })

            history.push(`/orders`);

            this.setState({
                psId: null,
                orderStatusId: null,
                datePickup: null,
                actualDatePickup: null,
                deliveryDate: null,
                psDeliveryDate: null,
                actualDeliveryDate: null,
                orderNum: "",
                clientOrderNum: "",
                clientNum: "",
                page: 0,
                pageSize: 10,
                orgId: null,
                psOrderNum: '',
                slqUser: {id: ''},
                psService: null,
                uslugaPs: [],
                isAlert: null
            });
            this.props.onSearch(agent.Orders.all(this.state.page, this.state.pageSize, ''));
        }

        this.downloadFile = (res) => {
            const contentDisposition = res?.headers['content-disposition'];
            if(contentDisposition) {
                const link = document.createElement('a');
                link.href = window.URL.createObjectURL(res?.body);
                link.download = decodeURI(contentDisposition)?.replace('UTF-8', '');
                link.click();
            }
        }

        this.excel = (code, category) => ev => {
            ev.preventDefault();
            const q = searchQuery(this.state);
            agent.Orders.getExcel(q + `&code=${code}&category=${category}`)
                .then(res => this.downloadFile(res));
        }

        this.longExcel = async (code, category) => {
            const params = searchQuery(this.state);
            return await agent.Reports.createReport(params + `&code=${code}&category=${category}`);
        }

        this.submitForm = () => ev => {
            ev.preventDefault();
            this.setState({first: 0, page: 0}, () => {
                const q = searchQuery(this.state);
                this.props.onSearch(agent.Orders.all(this.state.page, this.state.pageSize, q));

                history.push({
                    pathname: `/orders`,
                    search: q});
            })
        };

        addLocale('ru', {
            firstDayOfWeek: 1,
            dayNames: ["Понедельник", "Вторник", "Среда", "Четверг", "Пятница", "Суббота", "Воскресенье"],
            dayNamesShort: [ "Вск", "Пнд", "Втр", "Срд", "Чтв", "Птн", "Сбт"],
            dayNamesMin: ["Вс", "Пн", "Вт", "Ср", "Чт", "Пт", "Сб"],
            monthNames: ["Январь", "Февраль", "Март", "Апрель", "Май", "Июнь", "Июль", "Август", "Сентябрь", "Октябрь", "Ноябрь", "Декабрь"],
            monthNamesShort: ["Янв", "Фев", "Мрт", "Апр", "Май", "Июн", "Июл", "Авг", "Сен", "Окт", "Нбр", "Дек"],
            today: 'Сегодня',
            clear: 'Отчистить',
            dateFormat: 'dd.mm.yy',
            weekHeader: 'Sm'
        });
    }

    setOrderStatusId = (value) => {
        this.setState({
            orderStatusId: value
        })
    }

    // это нужно чтобы дата отображалась в компоненте calendar
    twoDatesFormat = (dates) => {
        if (dates) {
            const fromDate = dates.split('-')[0];
            const toDate = dates.split('-')[1];

            const fromDateString = fromDate;
            const toDateString = toDate;

            const fromDateParts = fromDateString.split('.');
            const toDateParts = toDateString.split('.');

            const fromDateObject = new Date(+fromDateParts[2], fromDateParts[1] - 1, +fromDateParts[0]);
            const toDateObject = new Date(+toDateParts[2], toDateParts[1] - 1, +toDateParts[0]);

            return [fromDateObject, toDateObject];
        }
    }

    psServiceFormat = (params, mainArray = []) => {
         // from url
         if (this.state.isLoaded) {
            if (params) {
                let filteredArr = [];

                // multiple
                if (params.includes(',')) {
                    const splittedParams = params.split(',');
                    for (let value of splittedParams) {
                        const item = mainArray.find(item => item.code === value);
                        if (item) {
                            filteredArr.push(item);    
                        }
                    }
                } else {
                    const item = mainArray.find(item => item.code === params);
                    filteredArr.push(item);
                }

                return filteredArr.map(item => item.code)
            }
         }
    }

    statusIdFormat = async (params) => {
        // from url
        if (this.state.isLoaded) {
            if (params) {
                let filteredArr = [];
                const orderStatus = await agent.OrdersStatus.all();

                // multiple
                if (params.includes(',')) {
                    const splittedParams = params.split(',');
                    for (let value of splittedParams) {
                        filteredArr.push(orderStatus.filter(item => item.id == value))
                    }
                } else {
                    filteredArr.push(orderStatus.filter(item => item.id == +params))
                }
                const statusesArr = filteredArr.map(item => item.map(item => item.id));

                let finalArr = [];
                for (let i = 0; i < statusesArr.length; i++) {
                    finalArr.push(...statusesArr[i])
                }

                return finalArr;
            }
        }
    }

    async componentDidMount() {
        let q = window.location.search;
        if (q) {
            q = '&' + q.substring(1, q.length);
        }
        const postal = await agent.CalcTariff.getPostalServices();
        this.props.onLoad
        (
            agent.PostalService.getAllForFilter(),
            agent.OrdersStatus.all(),
            agent.Orders.all(this.state.page, this.state.pageSize, q),
            agent.Report.get('order-list'),
            agent.Organizations.all(),
            agent.Organizations.clients(),
            postal
        );

        this.props.onMount({
            params: window.location.search,
            urlValue: window.location.search ? true : false,
        });

        const p = new URLSearchParams(window.location.search);
        const psId = p.get('ps') || null;
        if (psId) {
            const id = this.props.postalServices.find(item => item.code === psId) || null;
            this.handlePostalServiceChange(id);
        }
        const status = await this.statusIdFormat(p.get('status'));
        console.log(p, p.get('clientNum'));
        this.setState({
            psId: psId,
            orderStatusId: status,
            datePickup: this.twoDatesFormat(p.get('take')),
            actualDatePickup: this.twoDatesFormat(p.get('atake')),
            deliveryDate: this.twoDatesFormat(p.get('delivery')),
            psDeliveryDate: this.twoDatesFormat(p.get('psdelivery')),
            actualDeliveryDate: this.twoDatesFormat(p.get('adelivery')),
            orderNum: p.get('orderNum') || "",
            clientOrderNum: p.get('clientOrderNum') || "",
            clientNum: p.get('clientNum') || "",
            page: +p.get('page'),
            pageSize: +p.get('pageSize') || 10,
            orgId: +p.get('orgId') || null,
            psOrderNum: p.get('psOrderNum') || "",
            updateMount: true,
        });
    }

    async componentDidUpdate(prevProps) {
        if (prevProps !== this.props) {
            if (this.props.orderStatuses) {
                this.setState({isLoaded: true});
                if (window.location.search && (this.state.updateMount || prevProps.location.search !== this.props.location.search)) {
                    const p = new URLSearchParams(window.location.search);
                    const status = await this.statusIdFormat(p.get('status'));
                    this.setState({
                        psId: p.get('ps') || null,
                        orderStatusId: status,
                        datePickup: this.twoDatesFormat(p.get('take')),
                        actualDatePickup: this.twoDatesFormat(p.get('atake')),
                        deliveryDate: this.twoDatesFormat(p.get('delivery')),
                        psDeliveryDate: this.twoDatesFormat(p.get('psdelivery')),
                        actualDeliveryDate: this.twoDatesFormat(p.get('adelivery')),
                        orderNum: p.get('orderNum') || "",
                        clientOrderNum: p.get('clientOrderNum') || "",
                        clientNum: p.get('clientNum') || "",
                        page: +p.get('page'),
                        pageSize: +p.get('pageSize') || 10,
                        orgId: +p.get('orgId') || null,
                        psOrderNum: p.get('psOrderNum') || "",
                        updateMount: false,
                    });
                }
            }
        }
    }
    componentWillUnmount() {
        this.props.onUnload();
    }

    async handlePostalServiceChange(e) {
        if (e?.id) {
            const usluga = await agent.CalcTariff.getUslugaPs(e.id);
            const p = new URLSearchParams(window.location.search);
            const ps = this.psServiceFormat(p.get('psService'), usluga);
            this.setState({
                uslugaPs:  this.props.onPostalServicePicked(agent.CalcTariff.getUslugaPs(e.id)),
                psService: ps,
            });
            return;
        }
        this.setState({
            uslugaPs: null,
            psService: null,
            psId: null,
        });
    }

    render() {
        return (
            <div>
                {this.state.openedModals === modals[0] ? 
                    <ModalReports 
                        isModalOpen={this.state.openedModals === modals[0]} 
                        onClose={() => this.setOpenedModals('')} 
                        reports={this.props.reports||[]}
                        onCreateReport={this.longExcel}
                        onDownload={this.downloadFile}
                    /> : null
                }
                <Fieldset legend="Поиск" toggleable collapsed={this.state.panelCollapsed}
                          onToggle={(e) => this.setState({panelCollapsed: e.value})}>
                    <form onSubmit={this.submitForm()}>
                        <div className="p-fluid p-formgrid p-grid">
                            <div className="p-field p-col-6 p-md-3 hiddenInput">
                                <label htmlFor="orderNum">Трек номер SLQ</label>
                                <InputText id="orderNum" value={this.state.orderNum}
                                           onChange={(e) => this.setState({orderNum: e.target.value})} />
                            </div>
                            <div className="p-field p-col-6 p-md-3 hiddenInput">
                                <label htmlFor="clientNum">Клиентский номер заказа</label>
                                <InputText id="clientNum" value={this.state.clientNum}
                                           onChange={(e) => this.setState({clientNum: e.target.value})} />
                            </div>
                            <div className="p-field p-col-6 p-md-3 hiddenInput">
                                <label htmlFor="clientOrderNum">Клиентский номер доставки</label>
                                <InputText id="clientOrderNum" value={this.state.clientOrderNum}
                                           onChange={(e) => this.setState({clientOrderNum: e.target.value})} />
                            </div>
                            <div className="p-field p-col-6 p-md-3 hiddenInput">
                                <label htmlFor="psOrderNum">Трек номер грузоперевозчика</label>
                                <InputText id="psOrderNum" value={this.state.psOrderNum}
                                           onChange={(e) => this.setState({psOrderNum: e.target.value})} />
                            </div>
                        </div>
                        <div className="p-fluid p-formgrid p-grid">
                            <div className="p-field p-col-6 p-md-3 hiddenInput">
                                <label htmlFor="userGuid">Пользователь SLQ</label>
                                {/*<InputText id="userGuid" value={this.state.guid}*/}
                                {/*           onChange={(e) => this.setState({guid: e.target.value})} />*/}
                                <SearchSelect placeholder="Телефон/ФИО/БИН/Наименование компании" name="from"
                                              state={this.state.slqUser}
                                              setState={this.setSlqUser}
                                              options={this.props.slqUserOptions}
                                              optionValue={"id"}
                                              onChange={this.onChangeFrom}
                                              optionText={"name"}/>
                            </div>
                            <div className="p-field p-col-6 p-md-3">
                                <label htmlFor="psId">Грузоперевозчики</label>
                                <Dropdown inputId="psId" value={this.state.psId} 
                                    options={this.props.postalServices}
                                    onChange={(e) => {
                                        const id = this.props.postalServices.find(item => item.code === e.value) || null;
                                        this.setState({psId: e.value});
                                        this.handlePostalServiceChange(id);
                                    }}
                                    optionLabel="nameRu" 
                                    optionValue="code" 
                                    showClear
                                />
                            </div>
                            <div className="p-field p-col-6 p-md-3">
                                <label htmlFor="psService">Услуга грузоперевозчика</label>
                                 {this.state.isLoaded && (
                                    <MultiSelect
                                        inputId="psService"
                                        name="psService"
                                        value={this.state.psService}
                                        onChange={(e) => { this.setState({ psService: e.value }) }}
                                        options={this.props.uslugaPs}
                                        disabled={!this.state.psId} placeholder={!this.state.psId ? 'Выберите грузоперевозчика' : ''}
                                        optionLabel="code" optionValue="code"
                                    /> 
                                    )
                                 }
                            </div>
                            <div className="p-field p-col-6 p-md-3 hiddenInput">
                                <label htmlFor="clientId">Клиент</label>
                                <Dropdown inputId="clientId" value={this.state.orgId} options={this.props.orgs}
                                          onChange={(e) => this.setState({orgId: e.value})}
                                          optionLabel="name" optionValue="id" showClear/>
                            </div>
                        </div>
                        <div className="p-fluid p-formgrid p-grid">
                            <div className="p-field p-col-12 p-md-3">
                                <label htmlFor="firstname5">Контрольная дата забора</label>

                                <Calendar value={this.state.datePickup}
                                          onChange={(e) => this.setState({ datePickup: e.value})}
                                          selectionMode="range" readOnlyInput locale="ru"/>
                            </div>
                            <div className="p-field p-col-12 p-md-3">
                                <label htmlFor="firstname5">Фактическая дата забора</label>

                                <Calendar value={this.state.actualDatePickup}
                                          onChange={(e) => this.setState({ actualDatePickup: e.value})}
                                          selectionMode="range" readOnlyInput locale="ru"/>
                            </div>
                            <div className="p-field p-col-6 p-md-3 hiddenInput">
                                <label htmlFor="statusId">Статус</label>

                                {
                                this.state.isLoaded && (
                                    <MultiSelect
                                        inputId="statusId"
                                        value={this.state.orderStatusId}
                                        onChange={(e) => this.setOrderStatusId(e.value)}
                                        options={this.props.orderStatuses}
                                        optionLabel="nameRu" optionValue="id"
                                    />
                                )}
                            </div>
                            <div className="p-field p-col-6 p-md-3 hiddenInput">
                                <label htmlFor="clientCode">Признак клиента</label>
                                <Dropdown inputId="clientCode" value={this.state.clientCode} options={this.props.clients}
                                          onChange={(e) => this.setState({clientCode: e.value})}
                                          optionLabel="name" optionValue="code" showClear/>
                            </div>
                        </div>
                        <div className="p-fluid p-formgrid p-grid" style={{alignItems: "flex-end"}}>
                            <div className="p-field p-col-12 p-md-3">
                                <label htmlFor="firstname5">Контрольная дата доставки</label>

                                <Calendar value={this.state.deliveryDate}
                                          onChange={(e) => this.setState({ deliveryDate: e.value})}
                                          selectionMode="range" readOnlyInput locale="ru"/>
                            </div>
                            <div className="p-field p-col-12 p-md-3">
                                <label htmlFor="firstname5">Фактическая дата доставки</label>

                                <Calendar value={this.state.psDeliveryDate}
                                          onChange={(e) => this.setState({ psDeliveryDate: e.value})}
                                          selectionMode="range" readOnlyInput locale="ru"/>
                            </div>
                            <div className="p-field p-col-12 p-md-3">
                                <label htmlFor="with-notify">Уведомления</label>
                                <Dropdown inputId="with-notify" 
                                    value={this.state.isAlert} 
                                    options={[{label: 'Да', value: true}, {label: 'Нет', value: false}]}
                                    onChange={(e) => this.setState({isAlert: e.value})}
                                    optionLabel="label" optionValue="value" showClear
                                />
                            </div>
                        </div>
                        <br/>
                        <div className="p-grid p-justify-end">
                            <div className="p-mr-2">
                                <Button type="submit" label="Найти" autoFocus/>
                            </div>
                            <div className="p-mr-2">
                                <Button className="p-button-warning" label="Сбросить" onClick={this.clearForm()}/>
                            </div>
                        </div>
                        <br/>
                        <div className="p-grid p-justify-end">
                            <div className="p-mr-2">
                                <Button onClick={() => this.setOpenedModals(modals[0])} label="Формируемые отчеты" className="p-button-outlined" />
                            </div>
                        </div>
                        <span>Количество заказов: {(this.props.orders||{totalElements: 0}).totalElements}</span>
                    </form>
                    <br/>
                    <div className="p-grid p-justify-end">
                        {
                            (this.props.reports||[]).map((report, id) => {
                                return (
                                    <div key={id} className="p-mr-2 p-mt-3">
                                        <Button className="p-button-secondary" label={report.nameRu}
                                                onClick={this.excel(report.code, report.category)}/>
                                    </div>
                                )
                            })
                        }
                    </div>
                </Fieldset>
                <div className="datatable-doc-demo">
                    <div className="card">
                        <OrderTable orders={this.props.orders}/>
                        <Paginator currentPageReportTemplate="Показано с {first} по {last} из {totalRecords} записей"
                                   paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                                   first={this.state.first} rows={this.state.pageSize} totalRecords={(this.props.orders||{totalElements: 0}).totalElements} rowsPerPageOptions={[10, 25, 50, 'все']}
                                   onPageChange={this.onPageChange}/>
                    </div>
                </div>
            </div>
        )
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(Orders);
