import { useMemo } from "react";
import { useSelector } from "react-redux";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";

const TabTimeline = ({timelines}) => {
  const {statusesProcessing, statusesMonitors} = useSelector((state) => state?.orderStatus);

  const [tableOne, tableTwo] = useMemo(() => {
    const tableA = timelines.filter((timeline) => timeline.countPreOrder === 1);
    const tableB = timelines.filter((timeline) => timeline.countPreOrder === 2);
    return [tableA, tableB];
  }, [timelines]);
  

  const tableBodyStatus = ({status}) => {
    const stat = statusesMonitors?.find((stat) => stat.id === status);
    return (
      <div className="p-text-normal">{stat?.nameRu}</div>
    );
  };

  const tableBodyAdminStatus = ({statusAdmin}) => {
    const stat = statusesProcessing?.find((stat) => stat.id === statusAdmin);
    return (
      <div className="p-text-normal">{stat?.nameRu}</div>
    );
  };

  const tableBodyAlert = ({alert}) => {
    return (
      <div className="p-text-normal">{alert === true ? 'Да' : alert === false ? 'Нет' : ''}</div>
    );
  }

  const tableBodyActive = ({active}) => {
    return (
      <div className="p-text-normal">{active === true ? 'Нет' : active === false ? 'Да' : ''}</div>
    );
  }

  return (
    <>
      <div className="card p-mb-2 p-mt-1 p-d-flex p-flex-column">
        <h1 className="orderTitle p-mb-1 p-ml-0">Подзаказ 1</h1>
        <DataTable 
          value={tableOne} 
          id="list" 
          className="p-datatable-sm"
        >
          <Column field="status" columnKey="status" header="Статус" body={tableBodyStatus} style={{width: '13rem'}}></Column>
          <Column field="startDate" columnKey="startDate" header="Дата начала" style={{width: '10rem'}}></Column>
          <Column field="endDate" columnKey="endDate" header="Дата окончания" style={{width: '10rem'}}></Column>
          <Column field="active" columnKey="active" header="Выполнено" body={tableBodyActive} style={{width: '6.5rem'}}></Column>
          <Column field="alert" columnKey="alert" header="Уведомления" body={tableBodyAlert} style={{width: '7.5rem'}}></Column>
          <Column field="alertDate" columnKey="alertDate" header="Дата уведомления" style={{width: '10rem'}}></Column>
          <Column field="statusAdmin" columnKey="statusAdmin" header="Статус обработки" body={tableBodyAdminStatus} style={{width: '9.5rem'}}></Column>
          <Column field="comment" columnKey="comment" header="Комментарии"></Column>
        </DataTable>
      </div>
      {tableTwo.length ? 
        <div className="card p-mb-2 p-mt-1 p-d-flex p-flex-column">
          <h1 className="orderTitle p-mb-1 p-ml-0">Подзаказ 2</h1>
          <DataTable 
            value={tableTwo} 
            id="list" 
            className="p-datatable-sm"
          >
            <Column field="status" columnKey="status" header="Статус" body={tableBodyStatus} style={{width: '11rem'}}></Column>
            <Column field="startDate" columnKey="startDate" header="Дата начала" style={{width: '10rem'}}></Column>
            <Column field="endDate" columnKey="endDate" header="Дата окончания" style={{width: '10rem'}}></Column>
            <Column field="active" columnKey="active" header="Выполнено" style={{width: '10rem'}}></Column>
            <Column field="alert" columnKey="alert" header="Уведомления" style={{width: '10rem'}}></Column>
            <Column field="alertDate" columnKey="alertDate" header="Дата уведомления" style={{width: '10rem'}}></Column>
            <Column field="statusAdmin" columnKey="statusAdmin" header="Статус обработки" body={tableBodyAdminStatus} style={{width: '9.5rem'}}></Column>
            <Column field="comment" columnKey="comment" header="Комментарии"></Column>
          </DataTable>
        </div> : null
      }
    </>
  );
};


export default TabTimeline;