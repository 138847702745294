import { useSelector } from "react-redux";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { Dropdown } from "primereact/dropdown";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { ListBox } from 'primereact/listbox';
import agent from "../../../agent";
import { useState } from "react";

const initialOptions = {
  status: null,
  comment: '',
};

const DialogEditList = ({
  viewDialog,
  hideDialog,
  handleConfirm,
  list,
  showMessage
}) => {
  const {postalServices} = useSelector((state) => state?.calcTariff);
  const {statusesProcessing, statusesMonitors} = useSelector((state) => state?.orderStatus);
  const [options, setOptions] = useState(initialOptions);
  const [loading, setLoading] = useState(false);

  const handleOption = (key, value) => {
    setOptions({...options, [key]: value});
  };

  const onSave = async () => {
    if (!options.status) {
      showMessage('error', 'Ошибка', 'Заполните обязательное поле "Статус обработки"');
      return;
    }
    try {
      setLoading(true);
      const body = { 
        "ids" : list.map((order) => order.id),
        "statusAdmin" : options.status,
        "comment" : options.comment
      };
      await agent.StatusesMonitors.setTimelineOrder(body);
      setLoading(false);
      handleConfirm(body);
      showMessage('success', 'Редактирование статусов', 'Статусы успешно изменены');
    } catch (err) {
      showMessage('error', 'Ошибка', 'Возникла ошибка при изменении статусов');
    }
  };

  const dialogFooter = () => {
    return (
      <div>
        <Button label="Отмена" icon="pi pi-times" onClick={hideDialog} className="p-button-text" />
        <Button loading={loading} label="Сохранить" icon="pi pi-check" onClick={onSave} autoFocus />
      </div>
    );
  };

  const tableBodyCarrier = ({psId}) => {
    const postalServe = postalServices?.find((ps) => ps.code === psId);
    return (
      <div className="p-text-normal">{postalServe?.nameRu}</div>
    );
  };

  const tableBodyAdminStatus = ({statusAdmin}) => {
    const stat = statusesProcessing?.find((stat) => stat.id === statusAdmin);
    return (
      <div className="p-text-normal">{stat?.nameRu}</div>
    );
  };

  const tableBodyStatus = ({status}) => {
    const stat = statusesMonitors?.find((stat) => stat.id === status);
    return (
      <div className="p-text-normal">{stat?.nameRu}</div>
    );
  };

  return (
    <Dialog
      header={"Редактировать"} 
      visible={viewDialog} 
      style={{ width: '80vw' }} 
      footer={dialogFooter}
      onHide={hideDialog}
    >
      <div className="p-grid">
        <div className="card p-mb-2 p-mt-1 p-d-flex p-flex-column">
          <label className="p-mb-1" htmlFor="list">Выбранные заказы</label>
          <DataTable value={list} id="list"
            className="p-datatable-sm"
            //paginator
            //paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
            //currentPageReportTemplate="Showing {first} to {last} of {totalRecords}" 
            //rows={10} rowsPerPageOptions={[10,20,50]}
          >
            <Column field="orderNum" columnKey="orderNum" header="Номер заказа" style={{width: '9.5rem'}}></Column>
            <Column field="psId" columnKey="psId" header="Перевозчик" body={tableBodyCarrier} style={{width: '12rem'}}></Column>
            <Column field="status" columnKey="status" header="Статус" body={tableBodyStatus} style={{width: '11rem'}}></Column>
            <Column field="startDate" columnKey="startDate" header="Дата начала" style={{width: '10rem'}}></Column>
            <Column field="endDate" columnKey="endDate" header="Дата окончания" style={{width: '10rem'}}></Column>
            <Column field="statusAdmin" columnKey="statusAdmin" header="Статус обработки" body={tableBodyAdminStatus} style={{width: '9.5rem'}}></Column>
            <Column field="comment" columnKey="comment" header="Комментарии"></Column>
          </DataTable>
        </div>

        <div className="p-col-4 p-mb-2 p-d-flex p-flex-column">
          <label className="p-mb-1" htmlFor="process-status">Статус обработки</label>
          <Dropdown inputId="process-status" value={options.status} 
            options={statusesProcessing}
            onChange={(e) => handleOption('status', e.value)}
            optionLabel="nameRu" 
            optionValue="id" 
            showClear
          />
        </div>

        <div className="p-col-12 p-d-flex p-flex-column">
          <label className="p-mb-1" htmlFor="comment">Комментарии</label>
          <InputTextarea
            id={'comment'} 
            rows={3} 
            cols={50} 
            value={options.comment} 
            onChange={(e) => handleOption("comment", e.target.value)} 
            autoResize 
          />
        </div>
      </div>  
    </Dialog>
  );
};

export default DialogEditList;