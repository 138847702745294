import {
    ORDER_STATUS_LOADED, ORDER_STATUS_UNLOADED, 
    SET_STATUSSES_MONITORS, SET_STATUSSES_PROCESS
} from "../constants/actionTypes";

const defaultState = {
    orderStatuses: [],
    statusesMonitors: [],
    statusesProcessing: []
};

const orderStatusReducer = (state = defaultState, action) => {
    switch (action.type) {
        case ORDER_STATUS_LOADED:
            let orderStatuses = [];
            if(action.payload &&
                action.payload.hasOwnProperty('error') &&
                action.payload.error === 'Unauthorized') {
                orderStatuses = [];
            } else {
                orderStatuses =  action.payload;
            }
            return {
                ...state,
                orderStatuses: orderStatuses,
            };

        case SET_STATUSSES_MONITORS:
            return {
                ...state,
                statusesMonitors: action.payload
            };

        case SET_STATUSSES_PROCESS:
            return {
                ...state,
                statusesProcessing: action.payload
            };

        case ORDER_STATUS_UNLOADED:
            return [];

        default:
            return state;
    }
};
export default orderStatusReducer;