import { useEffect, useRef, useState } from "react";
import { Fieldset } from "primereact/fieldset";
import { Dropdown } from "primereact/dropdown";
import { Button } from "primereact/button";
import { Paginator } from "primereact/paginator";
import agent from "../../agent";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import DialogEdit from "./components/DialogEdit";
import DialogEditList from "./components/DialogEditList";
import { CALC_TARIFF_POSTAL_SERVICES_LOAD, SET_STATUSSES_MONITORS, SET_STATUSSES_PROCESS } from "../../constants/actionTypes";
import { useDispatch, useSelector } from "react-redux";
import { Toast } from "primereact/toast";
import {Link} from "react-router-dom";

const initialFilters = {
  carrier: null,
  status: null,
  processingStatus: null
};

const initialPageConfig = {
  page: 1,
  pageSize: 10,
  totalElements: 0
};

const modals = ['edit', 'edit-many'];

const StatusMonitoring = () => {
  const dispatch = useDispatch();
  const toast = useRef(null);
  const {postalServices} = useSelector((state) => state?.calcTariff);
  const {statusesMonitors, statusesProcessing} = useSelector((state) => state?.orderStatus);
  const [filters, setFilters] = useState(initialFilters);
  const [pageConfig, setPageConfig] = useState(initialPageConfig);
  const [openedModal, setOpenedModal] = useState('');
  const [orders, setOrders] = useState([]);
  const [selectedOrders, setSelectedOrders] = useState([]);
  const [editOrder, setEditOrder] = useState(null);

  const fetchOptions = async () => {
    const postalServices = await agent.CalcTariff.getPostalServices();
    const statusses = await agent.StatusesMonitors.getTimelineStatusses();
    const statusesAdmin = await agent.StatusesMonitors.getAdminStatusses();
    dispatch({ type: CALC_TARIFF_POSTAL_SERVICES_LOAD, payload: postalServices });
    dispatch({ type: SET_STATUSSES_MONITORS, payload: statusses });
    dispatch({ type: SET_STATUSSES_PROCESS, payload: statusesAdmin });
  };

  useEffect(() => {
    fetchOptions();
  }, []);

  useEffect(() => {
    fetchOrders(filters, 0, pageConfig.pageSize);
  }, []);

  useEffect(() => {
    if (editOrder) {
      setOpenedModal(modals[0]);
    } else {
      setOpenedModal('');
    }
  }, [editOrder]);

  const handleFilters = (key, value) => {
    setFilters({...filters, [key]: value});
  };

  const handleClean = () => {
    setFilters(initialFilters);
    fetchOrders({
      carrier: null,
      status: null,
      processingStatus: null
    }, 0, pageConfig.pageSize);
  };

  const handleFind = async () => {
    await fetchOrders(filters,  0, pageConfig.pageSize);
  };

  const fetchOrders = async (options, page = 0, pageSize = 10) => {
    const data = await agent.StatusesMonitors.getOrdersList({
      status: options.status || undefined, 
      statusAdmin: options.processingStatus || undefined, 
      ps: options.carrier || undefined
    }, page, pageSize);
    
    setPageConfig({
      ...pageConfig, 
      page: data?.number,
      pageSize: data?.size, 
      totalElements: data?.totalElements
    });
    setOrders(data?.content || []);
    setSelectedOrders([]);
  };

  const handlePageChange = async (event) => {
    await fetchOrders(filters, event.page, event.rows);
  };

  const tableBodyCarrier = ({psId}) => {
    const postalServe = postalServices?.find((ps) => ps.code === psId);
    return (
      <div className="p-text-normal">{postalServe?.nameRu}</div>
    );
  };

  const tableBodyAdminStatus = ({statusAdmin}) => {
    const stat = statusesProcessing?.find((stat) => stat.id === statusAdmin);
    return (
      <div className="p-text-normal">{stat?.nameRu}</div>
    );
  };

  const tableBodyEdit = (rowData) => {
    const onEdit = (e) => {
      e.stopPropagation();
      setEditOrder(rowData);
    };

    return (
      <div className="p-d-flex p-jc-center">
        <Button
          onClick={(e) => onEdit(e, rowData)}
          className="p-button-text p-button-rounded" 
          icon="pi pi-pencil" 
        />
      </div>
    );
  };

  const tableBodyOrdernum = ({orderNum}) => {
    return (
      <Link to={`/order/${orderNum}`} className="order__link">{orderNum}</Link>
    );
  };

  const tableBodyStatus = ({status}) => {
    const stat = statusesMonitors?.find((stat) => stat.id === status);
    return (
      <div className="p-text-normal">{stat?.nameRu}</div>
    );
  }

  const showMessage = (severity, summary, detail, life = 5000, sticky = false) => {
    toast.current.show({severity, summary, detail, life, sticky });
  };

  const onSuccessEdit = ({ids, statusAdmin, comment}) => {
    setOrders(orders.map((order) => {
      const finOrder = ids.find((id) => id === order.id);
      if (finOrder) {
        return {...order, comment, statusAdmin}
      } else {
        return order;
      }
    }));
    setOpenedModal('');
  };

  return (
    <div className="p-mb-5">
      <Toast ref={toast} />
      {editOrder ? 
        <DialogEdit
          viewDialog={openedModal === modals[0]}
          hideDialog={() => setEditOrder(null)}
          handleConfirm={onSuccessEdit}
          order={editOrder}
          showMessage={showMessage}
        /> : null
      }
      {openedModal === modals[1] ? 
        <DialogEditList
          viewDialog={openedModal === modals[1]}
          hideDialog={() => setOpenedModal('')}
          handleConfirm={onSuccessEdit}
          list={selectedOrders}
          showMessage={showMessage}
        /> : null
      }
      <div className="p-d-flex p-jc-between p-ai-center">
        <h2>Мониторинг статусов</h2>
      </div>

      <Fieldset legend="Header" toggleable>
        <div className="p-fluid p-formgrid p-grid p-mb-4">
          <div className="p-col-3 p-mr-4 p-d-flex p-flex-column">
            <label className="p-mb-1" htmlFor="psId">Перевозчик</label>
            <Dropdown inputId="psId" value={filters.carrier} 
              options={postalServices}
              onChange={(e) => handleFilters('carrier', e.value)}
              optionLabel="nameRu" 
              optionValue="code" 
              showClear
            />
          </div>

          <div className="p-col-3 p-mr-4 p-d-flex p-flex-column">
            <label className="p-mb-1" htmlFor="status">Статус</label>
            <Dropdown inputId="status" value={filters.status} 
              options={statusesMonitors}
              onChange={(e) => handleFilters('status', e.value)}
              optionLabel="nameRu" 
              optionValue="id" 
              showClear
            />
          </div>

          <div className="p-col-3 p-mr-3 p-d-flex p-flex-column">
            <label className="p-mb-1" htmlFor="process-status">Статус обработки</label>
            <Dropdown inputId="process-status" value={filters.processingStatus} 
              options={statusesProcessing}
              onChange={(e) => handleFilters('processingStatus', e.value)}
              optionLabel="nameRu" 
              optionValue="id" 
              showClear
            />
          </div>
        </div>

        <div className="p-grid p-justify-end">
          <div className="p-mr-2">
            <Button className="p-button-warning" label="Сбросить" onClick={handleClean}/>
          </div>
          <div className="p-mr-2">
            <Button onClick={handleFind} label="Найти"/>
          </div>
        </div>

        <div className="p-d-flex p-ai-center">
          <Button 
            onClick={() => setOpenedModal(modals[1])} 
            label="Изменить статус" 
            className="p-button-sm" 
            disabled={!selectedOrders.length}
          />

          {selectedOrders.length ?
            <div className="p-ml-2 p-text-normal">
              Выбрано количество строк: {selectedOrders.length}
            </div> : null
          }
        </div>
      </Fieldset>
      <div className="datatable-doc-demo">
        <div className="card">
          <div className="card">
            <DataTable
              selection={selectedOrders} 
              onSelectionChange={(e) => setSelectedOrders(e.value)}
              value={orders}
              className="p-datatable-customers p-datatable-sm" dataKey="id" rowHover
              emptyMessage="Данные не найдены"
            >
              <Column selectionMode="multiple" headerStyle={{ width: '3rem' }}></Column>
              <Column field="orderNum" columnKey="orderNum" header="Номер заказа" body={tableBodyOrdernum} style={{width: '10rem'}}/>
              <Column field="psId" columnKey="psId" header="Перевозчик" body={tableBodyCarrier} style={{width: '12rem'}} />
              <Column field="status" columnKey="status" header="Статус" body={tableBodyStatus} style={{width: '12rem'}} />
              <Column field="startDate" columnKey="startDate" header="Дата начала" style={{width: '11rem'}} />
              <Column field="endDate" columnKey="endDate" header="Дата окончания" style={{width: '11rem'}} />
              <Column field="statusAdmin" columnKey="statusAdmin" header="Статус обработки" body={tableBodyAdminStatus} style={{width: '11rem'}} />
              <Column field="comment" columnKey="comment" header="Комментарии" />
              <Column field="id" columnKey="id" body={tableBodyEdit} style={{width: '4rem'}} />
            </DataTable>
          </div>
          <Paginator
            currentPageReportTemplate="Показано с {first} по {last} из {totalRecords} записей"
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            first={pageConfig.page*pageConfig.pageSize} 
            page={pageConfig.page}
            rows={pageConfig.pageSize}
            totalRecords={pageConfig.totalElements} 
            rowsPerPageOptions={[5, 10, 25, 50]}
            onPageChange={handlePageChange}
          />
        </div>
      </div>
    </div>
  );
};

export default StatusMonitoring;