import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import agent from "../../../agent";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { Dropdown } from "primereact/dropdown";
import { useSelector } from "react-redux";
import { useState } from "react";

const initialOptions = {
  status: null,
  comment: '',
};

const DialogEdit = ({
  viewDialog,
  hideDialog,
  handleConfirm,
  order,
  showMessage
}) => {
  const {postalServices} = useSelector((state) => state?.calcTariff);
  const {statusesMonitors, statusesProcessing} = useSelector((state) => state?.orderStatus);
  const [options, setOptions] = useState({
    ...initialOptions,
    comment: order?.comment,
    status: order?.status
  });
  const [loading, setLoading] = useState(false);

  const handleOption = (key, value) => {
    setOptions({...options, [key]: value});
  };

  const dialogFooter = () => {
    return (
      <div>
        <Button label="Закрыть" icon="pi pi-times" onClick={hideDialog} className="p-button-text" />
        <Button loading={loading} label="Сохранить" icon="pi pi-check" onClick={handleSave} autoFocus />
      </div>
    );
  };

  const handleSave = async () => {
    if (!options.status) {
      showMessage('error', 'Ошибка', 'Заполните обязательное поле "Статус обработки"');
      return;
    }
    try {
      setLoading(true);
      const body = { 
        "ids" : [order.id],
        "statusAdmin" : options.status,
        "comment" : options.comment
      };
      await agent.StatusesMonitors.setTimelineOrder(body);
      setLoading(false);
      handleConfirm(body);
      showMessage('success', 'Редактирование статусов', 'Статусы успешно изменены');
    } catch (err) {
      showMessage('error', 'Ошибка', 'Возникла ошибка при изменении статусов');
    }
  };

  const getCarrier = (psId) => {
    const postalServe = postalServices?.find((ps) => ps.code === psId);
    return postalServe?.nameRu;
  };

  const getStatus = (statusId) => {
    const status = statusesMonitors?.find((stat) => stat.id === statusId);
    return status?.nameRu;
  };

  return (
    <Dialog
      header={"Редактировать"}
      visible={viewDialog} 
      style={{ width: '60vw' }}
      footer={dialogFooter}
      onHide={hideDialog}
    >
      <div className="p-grid">
        <div className="p-field p-col-4 p-d-flex p-flex-column">
          <label htmlFor="order-num">Номер заказа</label>
          <InputText
            id={'order-num'} 
            type="text" 
            className={'p-mt-1 p-d-block p-mb-2'} 
            placeholder="Номер заказа" 
            value={order?.orderNum}
            disabled
          />
        </div>

        <div className="p-field p-col-4 p-d-flex p-flex-column">
          <label htmlFor="carrier">Перевозчик</label>
          <InputText
            id={'carrier'} 
            type="text" 
            className={'p-mt-1 p-d-block p-mb-2'} 
            placeholder="Перевозчик" 
            value={getCarrier(order?.psId)}
            disabled
          />
        </div>

        <div className="p-field p-col-4 p-d-flex p-flex-column">
          <label htmlFor="status">Статус</label>
          <InputText
            id={'status'} 
            type="text" 
            className={'p-mt-1 p-d-block p-mb-2'} 
            placeholder="Статус" 
            value={getStatus(order?.status)}
            disabled
          />
        </div>

        <div className="p-field p-col-6 p-d-flex p-flex-column">
          <label className="p-mb-1" htmlFor="process-status">Статус обработки</label>
          <Dropdown inputId="process-status" value={options.status} 
            options={statusesProcessing}
            onChange={(e) => handleOption('status', e.value)}
            optionLabel="nameRu" 
            optionValue="id" 
            showClear
          />
        </div>

        <div className="p-field p-col-12 p-d-flex p-flex-column">
          <label className="p-mb-1" htmlFor="comment">Комментарии</label>
          <InputTextarea
            id={'comment'} 
            rows={5} 
            cols={50} 
            value={options.comment} 
            onChange={(e) => handleOption('comment', e.target.value)} 
            autoResize 
          />
        </div>
      </div>  
    </Dialog>
  );
};

export default DialogEdit;